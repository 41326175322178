/* tslint:disable */

declare var Object: any;
export interface SyncedPatientsInterface {
  "date": Date;
  "createdPatientsCount": number;
  "syncedPatientIds"?: any;
  "dashboardPatientsCount"?: number;
  "id"?: any;
}

export class SyncedPatients implements SyncedPatientsInterface {
  "date": Date;
  "createdPatientsCount": number;
  "syncedPatientIds": any;
  "dashboardPatientsCount": number;
  "id": any;
  constructor(data?: SyncedPatientsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SyncedPatients`.
   */
  public static getModelName() {
    return "SyncedPatients";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SyncedPatients for dynamic purposes.
  **/
  public static factory(data: SyncedPatientsInterface): SyncedPatients{
    return new SyncedPatients(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SyncedPatients',
      plural: 'SyncedPatients',
      path: 'SyncedPatients',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "createdPatientsCount": {
          name: 'createdPatientsCount',
          type: 'number'
        },
        "syncedPatientIds": {
          name: 'syncedPatientIds',
          type: 'any',
          default: <any>null
        },
        "dashboardPatientsCount": {
          name: 'dashboardPatientsCount',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
