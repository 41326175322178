import { Component, OnInit } from '@angular/core';
import { AccessTokenApi, LoopBackAuth, SDKToken, AccessTokenInterface, CoachApi } from '@derena/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private tokenApi: AccessTokenApi, private authService: LoopBackAuth, private route: ActivatedRoute, private coachApi: CoachApi, private router: Router) { }

  submitting: boolean = false;
  hidePassword: boolean = true;
  hideConfirmedPassword: boolean = true;
  submittingErrorMsg = '';

  passwordFormControl = new FormControl('', [
    Validators.required, Validators.minLength(8)
  ]);

  passwordConfirmationFormControl = new FormControl('', [
    Validators.required, Validators.minLength(8)
  ]);

  ngOnInit() {
    const accessToken = this.route.snapshot.queryParams.access_token;
    this.authService.setToken(new SDKToken({ id: accessToken }));
  }

  async changePassword() {
    this.submitting = true;
    if(!this.checkIfPasswordsMatch() || !this.areValid()){
      this.submitting = false;
      return;
    }

    try{
      await this.coachApi.setPassword(this.passwordFormControl.value).toPromise();
      this.router.navigateByUrl('/login');
    } catch(e) {
      Swal.fire('Fehler', 'Dieser Link ist abgelaufen und funktioniert nicht länger', 'error').then((result) => {
        this.router.navigateByUrl('/coach-reset-password');
      });
    }
    this.submitting = false;
  }

  checkIfPasswordsMatch(): boolean {
    let isMatch = this.passwordFormControl.value === this.passwordConfirmationFormControl.value;
    if(!isMatch){
      this.submittingErrorMsg = 'Ihre neuen Passwörter stimmen nicht überein!'
    }

    return isMatch;
  }

  areValid(): boolean{
    let areValid = this.passwordFormControl.valid && this.passwordConfirmationFormControl.valid;
    if(!areValid){
      this.submittingErrorMsg = 'Bitte überprüfen Sie alle Felder!';
    }
    return areValid;
  }
}
