import {Component, OnInit} from '@angular/core';
import {LoopBackAuth} from '@derena/api';
import { SystemService } from '../../../system.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    showMenu: string;
    showAdminItems: boolean;
    showStatsItems: boolean;
    showCreateAccess: boolean = true;

    constructor(private authInfo: LoopBackAuth, private settings: SystemService) {
        const userData = this.authInfo.getCurrentUserData();
        if (userData) {
          this.showAdminItems = !!userData.isAdmin;
          this.showStatsItems = !!userData.isHealthManager;
        }
        this.settings.getAsync()
        .then((settings) => {
            this.showCreateAccess = this.showAdminItems ? true : settings.enable_creating_new_patients;
        }).catch(err => console.log('error getting system settings >', err));
    }

    ngOnInit() {
        this.showMenu = '';
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }
}
