import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminGuard, AuthGuard} from './shared/guard';
import {LayoutComponent} from './layout/layout.component';
import {LayoutModule} from './layout/layout.module';
import { InternetExplorerMsgComponent } from './internet-explorer-msg/internet-explorer-msg.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { CoachResetPasswordComponent } from './coach-reset-password/coach-reset-password.component';
import { CoachResetPasswordModule } from './coach-reset-password/coach-reset-password.module';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'create-access',
                loadChildren: './access-code/access-code.module#AccessCodeModule'
            },
            {
                path: 'patient',
                loadChildren: './patient/patient.module#PatientModule'
            },
            {
                path: 'admin',
                loadChildren: './admin/admin.module#AdminModule'
            },
            {
                path: 'settings',
                loadChildren: './settings/settings.module#SettingsModule',
            },
            {
                path: 'information',
                loadChildren: './information/information.module#InformationModule'
            },
            {
                path: 'stats',
                loadChildren: './stats/stats.module#StatsModule',
                canActivate: []
            },
            {
                path: 'export',
                loadChildren: './export/export.module#ExportModule',
                canActivate: []
            },
            {
                path: 'news-dashboard',
                loadChildren: './news-dashboard/news-dashboard.module#NewsDashboardModule'
            },
        ]
    },
    {
        path: 'print/patient',
        loadChildren: './patient/patient.module#PatientModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'print/access-code',
        loadChildren: './access-code/access-code.module#AccessCodeModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'internet-explorer-msg',
        component: InternetExplorerMsgComponent
    },
    {
        path: 'reset-password',
        pathMatch: 'prefix',
        component: ResetPasswordComponent
        // loadChildren: () => import('./reset-password/reset-password.module').then(mod => mod.ResetPasswordModule)
    },
    {
        path: 'coach-reset-password',
        component: CoachResetPasswordComponent
    }
];

@NgModule({
    imports: [
        LayoutModule,
        ResetPasswordModule,
        CoachResetPasswordModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
