/* tslint:disable */
import { Injectable } from '@angular/core';
import { AccessToken } from '../../models/AccessToken';
import { AppSettings } from '../../models/AppSettings';
import { Coach } from '../../models/Coach';
import { Email } from '../../models/Email';
import { System } from '../../models/System';
import { Patient } from '../../models/Patient';
import { PatientToken } from '../../models/PatientToken';
import { Survey } from '../../models/Survey';
import { ReplicationEndpoint } from '../../models/ReplicationEndpoint';
import { Device } from '../../models/Device';
import { PatientPuk } from '../../models/PatientPuk';
import { SyncedPatients } from '../../models/SyncedPatients';
import { ExternalInterface } from '../../models/ExternalInterface';
import { CostRate } from '../../models/CostRate';
import { Comment } from '../../models/Comment';
import { Diagnosis } from '../../models/Diagnosis';
import { ExternalComments } from '../../models/ExternalComments';
import { SurveyQuestion } from '../../models/SurveyQuestion';
import { SurveyAnswer } from '../../models/SurveyAnswer';
import { QuestionAnswerMapping } from '../../models/QuestionAnswerMapping';
import { Log } from '../../models/Log';
import { NewsInterface } from '../../models/NewsInterface';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    AccessToken: AccessToken,
    AppSettings: AppSettings,
    Coach: Coach,
    Email: Email,
    System: System,
    Patient: Patient,
    PatientToken: PatientToken,
    Survey: Survey,
    ReplicationEndpoint: ReplicationEndpoint,
    Device: Device,
    PatientPuk: PatientPuk,
    SyncedPatients: SyncedPatients,
    ExternalInterface: ExternalInterface,
    CostRate: CostRate,
    Comment: Comment,
    Diagnosis: Diagnosis,
    ExternalComments: ExternalComments,
    SurveyQuestion: SurveyQuestion,
    SurveyAnswer: SurveyAnswer,
    QuestionAnswerMapping: QuestionAnswerMapping,
    Log: Log,
    NewsInterface: NewsInterface,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
