/* tslint:disable */
import {
  Patient,
  SurveyAnswer
} from '../index';

declare var Object: any;
export interface QuestionAnswerMappingInterface {
  "text"?: string;
  "id"?: any;
  "patientId"?: any;
  "answerId"?: any;
  patient?: Patient;
  answer?: SurveyAnswer;
}

export class QuestionAnswerMapping implements QuestionAnswerMappingInterface {
  "text": string;
  "id": any;
  "patientId": any;
  "answerId": any;
  patient: Patient;
  answer: SurveyAnswer;
  constructor(data?: QuestionAnswerMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `QuestionAnswerMapping`.
   */
  public static getModelName() {
    return "QuestionAnswerMapping";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of QuestionAnswerMapping for dynamic purposes.
  **/
  public static factory(data: QuestionAnswerMappingInterface): QuestionAnswerMapping{
    return new QuestionAnswerMapping(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'QuestionAnswerMapping',
      plural: 'QuestionAnswerMappings',
      path: 'QuestionAnswerMappings',
      idName: 'id',
      properties: {
        "text": {
          name: 'text',
          type: 'string',
          default: 'null'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "patientId": {
          name: 'patientId',
          type: 'any'
        },
        "answerId": {
          name: 'answerId',
          type: 'any'
        },
      },
      relations: {
        patient: {
          name: 'patient',
          type: 'Patient',
          model: 'Patient',
          relationType: 'belongsTo',
                  keyFrom: 'patientId',
          keyTo: 'id'
        },
        answer: {
          name: 'answer',
          type: 'SurveyAnswer',
          model: 'SurveyAnswer',
          relationType: 'belongsTo',
                  keyFrom: 'answerId',
          keyTo: 'id'
        },
      }
    }
  }
}
