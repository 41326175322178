/* tslint:disable */

declare var Object: any;
export interface ReplicationEndpointInterface {
  "name": string;
  "host": string;
  "port": number;
  "protocol": string;
  "adminHost": string;
  "adminPort": number;
  "adminProtocol": string;
  "username": string;
  "password": string;
  "id"?: any;
}

export class ReplicationEndpoint implements ReplicationEndpointInterface {
  "name": string;
  "host": string;
  "port": number;
  "protocol": string;
  "adminHost": string;
  "adminPort": number;
  "adminProtocol": string;
  "username": string;
  "password": string;
  "id": any;
  constructor(data?: ReplicationEndpointInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ReplicationEndpoint`.
   */
  public static getModelName() {
    return "ReplicationEndpoint";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ReplicationEndpoint for dynamic purposes.
  **/
  public static factory(data: ReplicationEndpointInterface): ReplicationEndpoint{
    return new ReplicationEndpoint(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ReplicationEndpoint',
      plural: 'ReplicationEndpoints',
      path: 'ReplicationEndpoints',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "host": {
          name: 'host',
          type: 'string'
        },
        "port": {
          name: 'port',
          type: 'number'
        },
        "protocol": {
          name: 'protocol',
          type: 'string'
        },
        "adminHost": {
          name: 'adminHost',
          type: 'string'
        },
        "adminPort": {
          name: 'adminPort',
          type: 'number'
        },
        "adminProtocol": {
          name: 'adminProtocol',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
