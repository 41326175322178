import {LayoutModule} from '@angular/cdk/layout';
import {OverlayModule} from '@angular/cdk/overlay';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoopBackConfig, SDKBrowserModule} from '@derena/api';
import {environment} from '../environments/environment';
import {EndpointInterceptor} from './login/endpoint.interceptor';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { InternetExplorerMsgComponent } from './internet-explorer-msg/internet-explorer-msg.component';
import * as Sentry from "@sentry/browser";
import { MatNativeDateModule } from '@angular/material';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

Sentry.init({
    dsn: "https://fc5cd6c4fb6f47de9de7e4527cc7ddaa@sentry.solutec.de/3"
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(exception) {
        if (!exception)
            return;

        let error = exception;
        if (exception.original)
            error = exception.originalError;
        if (exception.rejection)
            error = exception.rejection;

        console.warn(error.message, error);
        Sentry.captureException(error);
    }
}

@NgModule({
    declarations: [AppComponent, InternetExplorerMsgComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        SDKBrowserModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
         MatNativeDateModule
    ],
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(translateService: TranslateService) {
        LoopBackConfig.setBaseURL(environment.apiEndpoint);
        translateService.setDefaultLang('de');
        translateService.use('de');
    }
}

