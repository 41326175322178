import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SDKBrowserModule } from '@derena/api';
import { MatSnackBarModule, MatButtonModule, MatCheckboxModule, MatInputModule, MatIconModule } from '@angular/material';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    SDKBrowserModule,
    ReactiveFormsModule
  ]
})
export class ResetPasswordModule { }
