/* tslint:disable */
import {
  Patient
} from '../index';

declare var Object: any;
export interface CommentInterface {
  "author"?: string;
  "text"?: string;
  "type"?: string;
  "created"?: Date;
  "id"?: any;
  "patientId"?: any;
  patient?: Patient;
}

export class Comment implements CommentInterface {
  "author": string;
  "text": string;
  "type": string;
  "created": Date;
  "id": any;
  "patientId": any;
  patient: Patient;
  constructor(data?: CommentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Comment`.
   */
  public static getModelName() {
    return "Comment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Comment for dynamic purposes.
  **/
  public static factory(data: CommentInterface): Comment{
    return new Comment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Comment',
      plural: 'Comments',
      path: 'Comments',
      idName: 'id',
      properties: {
        "author": {
          name: 'author',
          type: 'string'
        },
        "text": {
          name: 'text',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "patientId": {
          name: 'patientId',
          type: 'any'
        },
      },
      relations: {
        patient: {
          name: 'patient',
          type: 'Patient',
          model: 'Patient',
          relationType: 'belongsTo',
                  keyFrom: 'patientId',
          keyTo: 'id'
        },
      }
    }
  }
}
