/* tslint:disable */
import {
  SurveyQuestion
} from '../index';

declare var Object: any;
export interface SurveyAnswerInterface {
  "label": string;
  "type": string;
  "exportKey"?: string;
  "id"?: any;
  "questionId"?: any;
  question?: SurveyQuestion;
}

export class SurveyAnswer implements SurveyAnswerInterface {
  "label": string;
  "type": string;
  "exportKey": string;
  "id": any;
  "questionId": any;
  question: SurveyQuestion;
  constructor(data?: SurveyAnswerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SurveyAnswer`.
   */
  public static getModelName() {
    return "SurveyAnswer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SurveyAnswer for dynamic purposes.
  **/
  public static factory(data: SurveyAnswerInterface): SurveyAnswer{
    return new SurveyAnswer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SurveyAnswer',
      plural: 'SurveyAnswers',
      path: 'SurveyAnswers',
      idName: 'id',
      properties: {
        "label": {
          name: 'label',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "exportKey": {
          name: 'exportKey',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "questionId": {
          name: 'questionId',
          type: 'any'
        },
      },
      relations: {
        question: {
          name: 'question',
          type: 'SurveyQuestion',
          model: 'SurveyQuestion',
          relationType: 'belongsTo',
                  keyFrom: 'questionId',
          keyTo: 'id'
        },
      }
    }
  }
}
