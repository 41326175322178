/* tslint:disable */

declare var Object: any;
export interface SurveyInterface {
  "key"?: string;
  "title"?: string;
  "start"?: Date;
  "until"?: Date;
  "frequency"?: string;
  "interval"?: number;
  "questions"?: Array<any>;
}

export class Survey implements SurveyInterface {
  "key": string;
  "title": string;
  "start": Date;
  "until": Date;
  "frequency": string;
  "interval": number;
  "questions": Array<any>;
  constructor(data?: SurveyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Survey`.
   */
  public static getModelName() {
    return "Survey";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Survey for dynamic purposes.
  **/
  public static factory(data: SurveyInterface): Survey{
    return new Survey(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Survey',
      plural: 'Surveys',
      path: 'Surveys',
      idName: 'key',
      properties: {
        "key": {
          name: 'key',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "start": {
          name: 'start',
          type: 'Date'
        },
        "until": {
          name: 'until',
          type: 'Date'
        },
        "frequency": {
          name: 'frequency',
          type: 'string'
        },
        "interval": {
          name: 'interval',
          type: 'number'
        },
        "questions": {
          name: 'questions',
          type: 'Array&lt;any&gt;'
        },
      },
      relations: {
      }
    }
  }
}
