import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {LoopBackAuth} from '@derena/api';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private loginService: LoginService) {}

    canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(async resolve => {
            if (await this.loginService.tryLogin()) {
                return resolve(true);
            } else {
                this.router.navigate(['/login'], { queryParams: {returnUrl: state.url } });
                return resolve(false);
            }
        });
    }
}

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private auth: LoopBackAuth) {}

    canActivate() {
        let data = this.auth.getCurrentUserData();
        if (!data)
            return false;
        return !!data.isAdmin || !!data.isSuperAdmin;
    }
}
