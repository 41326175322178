import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CoachApi, Coach } from '@derena/api';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coach-reset-password',
  templateUrl: './coach-reset-password.component.html',
  styleUrls: ['./coach-reset-password.component.scss']
})
export class CoachResetPasswordComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(private coachApi: CoachApi, private router: Router) { }

  ngOnInit() {

  }

  async resetCoachPassword(){
    const isCoachLocked = await this.coachApi.isCoachLocked(this.emailFormControl.value.toLowerCase()).toPromise();
    if (isCoachLocked) {
      Swal.fire('Fehler', 'Dieses Konto ist gesperrt!', 'error');
      return;
    }
    try {
      await this.coachApi.resetPassword({email: this.emailFormControl.value.toLowerCase()}).toPromise();
      Swal.fire('Erfolg', 'Ihr Passwort wurde zurückgesetzt. Sie erhalten weitere Informationen per Email.', 'success').then(() => {
        this.router.navigate(['/login']);
      });
    }catch(err){
      Swal.fire('Fehler', 'Ihre E-Mail Adresse ist uns nicht bekannt', 'error');
      console.log("error >>>> ", err);
    }
  }
}
