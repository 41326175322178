import { Injectable } from '@angular/core';
import { Coach, LoopBackAuth, LoopBackFilter } from '@derena/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewsInterfaceApi } from 'src/api/services/custom/NewsInterface';

export interface ClNews {
    id: string;
    title: string;
    date: string;
    body: string;
    read: boolean;
}

export interface NewsCoach {
    id: string;
    newsId: string;
    coachId: string;
    read: boolean;
    news: ClNews;
}

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    private coach: Coach;

    private newsSub: BehaviorSubject<ClNews[]> = new BehaviorSubject([]);
    readonly newsObs: Observable<ClNews[]> = this.newsSub.asObservable();

    private unreadNewsSub: BehaviorSubject<ClNews[]> = new BehaviorSubject([]);
    readonly unreadNewsObs: Observable<ClNews[]> = this.unreadNewsSub.asObservable();

    constructor(
        private auth: LoopBackAuth,
        private newsInterface: NewsInterfaceApi
    ) {
        this.coach = this.auth.getCurrentUserData();
        this.checkNews();
    }

    async checkNews() {
        try {
            let newsList = [];
            let unreadNewsList = [];
            // return coach obj from news api here.......
            // use coach email as Id
            newsList = await this.newsInterface
                .checkNews({
                    email: this.coach.email,
                    name: this.coach.firstname + ' ' + this.coach.lastname,
                    instance: location.hostname,
                    id: null,
                    news: null,
                })
                .toPromise();
            this.newsSub.next(Object.assign(newsList));
            this.unreadNewsSub.next(Object.assign(newsList.filter(x => x.read === false)));
        } catch (err) {
            console.log(err);
        }
    }

    async switchNewsRead(news: ClNews) {
        const res = await this.newsInterface.markNewsAsRead({
            newsId: news.id,
            coachEmail: this.coach.email
        }).toPromise();

        this.checkNews();
    }
}

