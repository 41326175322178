/* tslint:disable */

declare var Object: any;
export interface ExternalCommentsInterface {
  "coachId": string;
  "patientId": string;
  "psyrenaId": string;
  "start": Date;
  "end": Date;
  "presence": boolean;
  "importDate": Date;
  "deleted"?: Date;
  "id"?: any;
}

export class ExternalComments implements ExternalCommentsInterface {
  "coachId": string;
  "patientId": string;
  "psyrenaId": string;
  "start": Date;
  "end": Date;
  "presence": boolean;
  "importDate": Date;
  "deleted": Date;
  "id": any;
  constructor(data?: ExternalCommentsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExternalComments`.
   */
  public static getModelName() {
    return "ExternalComments";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ExternalComments for dynamic purposes.
  **/
  public static factory(data: ExternalCommentsInterface): ExternalComments{
    return new ExternalComments(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ExternalComments',
      plural: 'ExternalComments',
      path: 'ExternalComments',
      idName: 'id',
      properties: {
        "coachId": {
          name: 'coachId',
          type: 'string'
        },
        "patientId": {
          name: 'patientId',
          type: 'string'
        },
        "psyrenaId": {
          name: 'psyrenaId',
          type: 'string'
        },
        "start": {
          name: 'start',
          type: 'Date'
        },
        "end": {
          name: 'end',
          type: 'Date'
        },
        "presence": {
          name: 'presence',
          type: 'boolean'
        },
        "importDate": {
          name: 'importDate',
          type: 'Date',
          default: new Date(0)
        },
        "deleted": {
          name: 'deleted',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
