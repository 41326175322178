import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoopBackAuth } from '@derena/api';
import { TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClNews, NewsService } from 'src/app/shared/services/news.service';
import { EndpointInterceptor } from '../../../login/endpoint.interceptor';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
    pushRightClass: string;
    title: string;

    newsSub: Subscription;
    unreadNews: ClNews[] = [];
    toolTip: string;

    constructor(
            public router: Router,
            private translate: TranslateService,
            private endpoint: EndpointInterceptor,
            private authService: LoopBackAuth,
            public newsService: NewsService
        ) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd && window.innerWidth <= 2500 && this.isToggled()) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.pushRightClass = 'push-right';
        this.title = this.endpoint.name;
        this.endpoint
            .changes()
            .subscribe(
                x => this.title = x.name
            )
        this.initNews();
    }

    ngOnDestroy(): void {
        this.newsSub.unsubscribe();
    }

    async initNews() {
        this.newsSub = this.newsService.unreadNewsObs.subscribe(
            (news) => {
                this.unreadNews = news.reverse();
                this.toolTip = this.unreadNews.length ? `Sie haben ${this.unreadNews.length} ungelesene News` : 'Keine neue News vorhanden';
            },
            (err) => {
                console.log(err);
            }
        );
    }

    goToNews(newsId: string) {
        this.router.navigate(['/news-dashboard'], {queryParams: {id: newsId}});
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    onLoggedOut() {
        this.authService.clear();
        localStorage.removeItem('auth');
        this.router.navigate(['/login']);
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    goToSettings(){
        this.router.navigate(['/settings']);
    }
}
