import {Injectable} from '@angular/core';
import {CoachApi, LoopBackAuth} from '@derena/api';
import {EndpointInterceptor} from '../../login/endpoint.interceptor';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(private coachApi: CoachApi,
                private endpoint: EndpointInterceptor,
                private auth: LoopBackAuth) { }

    async tryLogin(): Promise<boolean> {
        let userData = this.auth.getCurrentUserData();
        if (!userData)
            return false;

        const isUserLocked = await this.coachApi.isCoachLocked(userData.email).toPromise();
        if (isUserLocked) {
          await this.coachApi.logout().toPromise();
          return false;
        }

        // if user was loggen in another instance the loopback auth returns the logged user from the other instance..
        // in this case when we call update session we will receive a 401 Error (Unauthorized)
        try {
            await this.endpoint.updateSession();
        } catch (e) {
            console.log('error updating session >', e)
            return false;
        }

        await this.endpoint.init(userData.endpoints[0]);
        return true;
    }

    async login(username, password): Promise<boolean | null> {
        try {
            let result = await this.coachApi.login({
                email: username,
                password: password
            }).toPromise();

            if (result.id) {
                await this.endpoint.init(result.user.endpoints[0]);
                await this.endpoint.updateSession();
                return true;
            } else {
                return false;
            }
        } catch (e) {
            if (e.statusCode === 409 && e.message === 'user_locked') {
              Swal.fire('Login Fehler', 'Dieses Konto wurde gesperrt!', 'error');
              return null;
            }
            return false;
        }
    }
}
