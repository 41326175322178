import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-explorer-msg',
  templateUrl: './internet-explorer-msg.component.html',
  styleUrls: ['./internet-explorer-msg.component.scss']
})
export class InternetExplorerMsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
