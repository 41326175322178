import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {BehaviorSubject} from 'rxjs';

export type SystemSettings = {
    study_number_mode: 'alpha-numeric' | 'numeric' | string;
    next_patient_index_ref: number;
    next_patient_study_number: string;
    enable_creating_new_patients: boolean;
    show_patients_in_preparation: boolean;
    show_export_section: boolean;
};

@Injectable({ providedIn: 'root' })
export class SystemService {
    private settings = new BehaviorSubject<SystemSettings>(null);

    constructor(private http: HttpClient) { }

    async getAsync(): Promise<SystemSettings> {
        return (await this.http.get(
            `${environment.apiEndpoint}/api/Systems/settings`
        ).toPromise())['settings'];
    }

    fetch() {
        this.http
            .get(`${environment.apiEndpoint}/api/Systems/settings`)
            .subscribe(x => {
                console.log('x', x);
                this.settings.next(x['settings']);
            })
    }

    latest(): BehaviorSubject<SystemSettings> {
        return this.settings
    }
}
