/* tslint:disable */
import {
  SurveyAnswer
} from '../index';

declare var Object: any;
export interface SurveyQuestionInterface {
  "active": boolean;
  "questionTitle": string;
  "type": string;
  "id"?: any;
  answers?: SurveyAnswer[];
}

export class SurveyQuestion implements SurveyQuestionInterface {
  "active": boolean;
  "questionTitle": string;
  "type": string;
  "id": any;
  answers: SurveyAnswer[];
  constructor(data?: SurveyQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SurveyQuestion`.
   */
  public static getModelName() {
    return "SurveyQuestion";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SurveyQuestion for dynamic purposes.
  **/
  public static factory(data: SurveyQuestionInterface): SurveyQuestion{
    return new SurveyQuestion(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SurveyQuestion',
      plural: 'SurveyQuestions',
      path: 'SurveyQuestions',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "questionTitle": {
          name: 'questionTitle',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        answers: {
          name: 'answers',
          type: 'SurveyAnswer[]',
          model: 'SurveyAnswer',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'questionId'
        },
      }
    }
  }
}
